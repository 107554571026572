import React, { useState, useRef, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";
import SearchIcon from "assets/icons/search";
import InputAdornment from "@mui/material/InputAdornment";
import { Input } from "components/forms/input";
import CardArticle, { CardArticleSkeleton } from "components/card-article";
import Title from "components/title";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { graphql, navigate } from "gatsby";
import "assets/css/index.css";
import { parseArticles, parseArticle } from "utils/article";
import { Helmet } from "react-helmet";
import Zoom from "@mui/material/Zoom";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { PageContainer } from "components/layout";
import { getImage } from "utils/image";

interface Props {
  data: any;
}

export default function Index({ data }: Props): React.ReactNode {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [keyword, setKeyword] = React.useState("");

  const allArticles = React.useMemo(
    () => parseArticles(data.allArticles.nodes),
    []
  );

  const featuredArticle = React.useMemo(
    () => parseArticle(data.featuredArticle),
    []
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate(`/search?keyword=${keyword}`);
  };

  return (
    <PageContainer>
      <Helmet
        title={`Blog`}
        titleTemplate="Alfin's Blog | Personal blog by Alfin Syahruddin"
        meta={[
          {
            name: `description`,
            content: "Find the latest of my writing here.",
          },
        ]}
      />

      <GatsbySeo
        openGraph={{
          title: "Alfin's Blog | Personal blog by Alfin Syahruddin",
          description: "Find the latest of my writing here.",
          url: `/`,
          images: [
            {
              url: getImage("/assets/images/blog.png"),
              alt: "Image",
            },
          ],
        }}
      />

      <Grid container>
        <Grid item xs={12}>
          <Container maxWidth="md" sx={{ my: 12 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs sx={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    bottom: -175,
                    left: -150,
                    zIndex: -1,
                    display: "block",
                  }}
                >
                  {/* We use img because StaticImage is not working with theme condition */}
                  <img
                    placeholder="blurred"
                    src={getImage(
                      theme.palette.mode == "dark"
                        ? "/assets/images/decoration-dark.webp"
                        : "/assets/images/decoration.webp"
                    )}
                    alt="decoration"
                    width={450}
                  />
                </div>

                <Typography variant="h4">
                  Personal blog by Alfin Syahruddin
                </Typography>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  sx={{
                    mt: 2,
                    mb: 4,
                  }}
                >
                  Find the latest of my writing here.
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Input
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder="Search articles..."
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon
                          color={keyword.length == 0 ? "inherit" : "primary"}
                        />
                      </InputAdornment>
                    }
                  />
                </form>
              </Grid>

              {!isMobile && (
                <Grid item>
                  <StaticImage
                    placeholder="blurred"
                    src="../assets/images/writing.webp"
                    alt="writing"
                    width={300}
                    style={
                      {
                        // animation: "float 6s ease-in-out infinite",
                      }
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} sx={{ mt: 12 }}>
          <CardArticle
            size="large"
            isFeaturedArticle
            image={featuredArticle.hero_image}
            to={`/article/${featuredArticle.slug}`}
            title={featuredArticle.title}
            subtitle={featuredArticle.subtitle}
            tags={featuredArticle.tags}
            date={featuredArticle.formattedDate}
            time={featuredArticle.time}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 8 }} />
        </Grid>

        <Grid item xs={12} container spacing={6}>
          <Grid item xs={12}>
            <Title>Latest articles</Title>
          </Grid>

          {allArticles.map((article: any, index: number) => (
            <Zoom in mountOnEnter unmountOnExit key={article.id}>
              <Grid item xs={12} md={4} key={index}>
                <CardArticle
                  image={article.hero_image}
                  to={`/article/${article.slug}`}
                  title={article.title}
                  subtitle={article.subtitle}
                  tags={article.tags}
                  date={article.formattedDate}
                  time={article.time}
                />
              </Grid>
            </Zoom>
          ))}
        </Grid>
      </Grid>
    </PageContainer>
  );
}

export const query = graphql`
  query {
    featuredArticle: mdx(frontmatter: { isFeatured: { eq: true } }) {
      frontmatter {
        title
        subtitle
        date
        formattedDate: date(formatString: "D MMMM YYYY")
        tags
        hero_image
      }
      id
      slug
      body
      fields {
        readingTime {
          minutes
        }
      }
    }

    allArticles: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 9
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          date
          formattedDate: date(formatString: "D MMMM YYYY")
          tags
          hero_image
        }
        id
        slug
        body
        fields {
          readingTime {
            minutes
          }
        }
      }
    }
  }
`;
